import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import InstagramIcon from '../images/instagram.inline.svg'
import FacebookIcon from '../images/facebook.inline.svg'

const networks = {
  instagram: <InstagramIcon />,
  facebook: <FacebookIcon />,
}

const StyledIconLink = styled.a`
  color: ${(props) => props.theme.colors.text};

  &:focus,
  &:hover {
    color: ${(props) => props.theme.colors.accent};
  }
`

const StyledIconWrapper = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: 0 0.5rem;
  color: inherit;
  fill: currentColor;
  transition: all 200ms;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
`

const SocialIcon = (props) => (
  <StyledIconLink
    href={props.url}
    target="_blank"
    rel="noopener noreferrer"
    title={props.title}
    aria-label={props.title}
  >
    <StyledIconWrapper
      width={props.width || '32px'}
      height={props.height || '32px'}
    >
      {networks[props.network]}
    </StyledIconWrapper>
  </StyledIconLink>
)

SocialIcon.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  network: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
}

export default SocialIcon
