import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    background-color: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.text};
    font-family: ${(props) => props.theme.fonts.sans};
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: normal;
  }

  ol,
  ul,
  p {
    line-height: 1.4;
  }

  a {
    color: ${(props) => props.theme.colors.text};
    outline: none;
    text-decoration: none;
    transition: all 200ms;

    &:focus,
    &:hover {
      color: ${(props) => props.theme.colors.accent};
    }
  }

  img {
    max-width: 100%;
  }

  input {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
  }

  input[type=time]::-webkit-clear-button,
  input[type=date]::-webkit-clear-button,
  input[type=time]::-ms-clear,
  input[type=date]::-ms-clear {
    display: none;
  }

  ::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
  }

  blockquote {
    margin: 3rem 2rem 3rem 0;
    padding: 0.5rem 1rem 0.5rem 2rem;
    border-left: 4px solid ${(props) => props.theme.colors.text};
    font-style: italic;
    font-size: 1.5rem;
  }
`;

export default GlobalStyle;
