import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Box, Flex } from 'rebass/styled-components'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from 'gbimage-bridge'
import Obfuscate from 'react-obfuscate'

import Layout from '../components/Layout'
import SocialIcon from '../components/SocialIcon'
import MHLogo from '../images/mh-logo.inline.svg'
import HouzzLogo from '../images/houzz-logo.inline.svg'

const StyledBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const Content = styled.div`
  display: flex;
  padding: 0 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const StyledMHLogo = styled(MHLogo)`
  max-width: 320px;
`

const StyledHouzzLink = styled.a`
  color: ${(props) => props.theme.colors.text};
  fill: currentColor !important;
  transition: all 200ms;

  &:focus,
  &:hover {
    color: ${(props) => props.theme.colors.accent};
  }
`

const StyledHouzzLogo = styled(HouzzLogo)`
  max-width: 120px;
  margin: 0 1rem;
`

const Title = styled.h1`
  position: relative;
  margin-top: 2rem;
  margin-bottom: 4rem;
  font-size: 3rem;
  text-align: center;
  text-transform: uppercase;

  @media screen and (min-width: 768px) {
    font-size: 3.5rem;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -2rem;
    left: 50%;
    width: 60%;
    height: 3px;
    background-color: ${(props) => props.theme.colors.accent};
    transform: translateX(-50%);
  }
`

const Copyright = styled.p`
  font-size: 0.85rem;
  opacity: 0.7;
  text-align: center;
`

const IndexPage = ({ data }) => {
  const imageData = data.mainBg
  const image = getImage(imageData)

  // Use like this:
  const bgImage = convertToBgImage(image)

  return (
    <Layout>
      <StyledBackgroundImage Tag="section" {...bgImage} preserveStackingContext>
        <Content>
          <Box mt={[4, 5]}>
            <StyledMHLogo />
          </Box>

          <Title>Coming soon</Title>

          <p style={{ textAlign: 'center' }}>
            We're currently working on creating our new website.
          </p>

          <Flex alignItems="center" justifyContent="space-around" mb="3">
            <Box mr="2">Follow us on</Box>
            <SocialIcon
              network="instagram"
              title="Instagram"
              url="https://instagram.com/magdalenahopkinsinteriors"
            />
            {/* <SocialIcon
              network="facebook"
              title="Facebook"
              url="https://www.facebook.com/mhinteriors.eu/"
            /> */}
          </Flex>

          {/* <Flex alignItems="center" justifyContent="space-around" mb="5">
            See us on{' '}
            <StyledHouzzLink
              href="https://www.houzz.com/pro/mags1hop/__public"
              aria-label="Houzz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledHouzzLogo />
            </StyledHouzzLink>
          </Flex> */}

          <Flex mb="3" flexDirection={['column', 'row']} alignItems="center">
            <Box mx={[0, 3]} mb={[2, 0]}>
              Magdalena Hopkins
            </Box>
            <Box mx={[0, 3]} mb={[2, 0]}>
              <Obfuscate tel="+420776265463">+420 776 265 463</Obfuscate>
            </Box>
            <Box mx={[0, 3]} mb={[2, 0]}>
              <Obfuscate email="hopkins@mhinteriors.eu" />
            </Box>
          </Flex>

          <Flex>
            <a
              href="https://goo.gl/maps/KLbGySBkpqbhY7KU8"
              title="Show on map"
              target="_blank"
              rel="noopener noreferrer"
            >
              Holečkova 15, Prague 150 00, Czech Republic
            </a>
          </Flex>

          <Copyright>
            All rights reserved. mh&nbsp;interiors&nbsp;s.r.o.&nbsp;&copy;&nbsp;
            {new Date().getFullYear()}
          </Copyright>
        </Content>
      </StyledBackgroundImage>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage

export const pageQuery = graphql`
  query {
    mainBg: file(relativePath: { eq: "main-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 90
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          backgroundColor: "#564d49"
        )
      }
    }
  }
`
