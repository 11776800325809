import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import { Normalize } from 'styled-normalize'

import GlobalStyle from '../styles/globalStyle'
import theme from '../styles/theme'

const TemplateWrapper = ({ children, data }) => {
  const { title, description } = data.site.siteMetadata

  return (
    <ThemeProvider theme={theme}>
      <>
        <Normalize />
        <GlobalStyle />

        <Helmet>
          <html lang="en" />
          <title>{title}</title>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <meta name="description" content={description || ''} />
        </Helmet>

        <>{children}</>
      </>
    </ThemeProvider>
  )
}

const TemplateWrapperQuery = (props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data) => <TemplateWrapper data={data} {...props} />}
  />
)

export default TemplateWrapperQuery
